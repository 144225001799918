
$header-color: [#4A4A4A, #111];
$text-color: [#3A4145, #111];
$text-gray: #9EABB3;
$bg-disabled: #E9ECEF;

$border-text: #9EABB3;
$border-color: #BFC8CD;
$border-text-focus: #889093;
$border-focus: #98A0A4;
